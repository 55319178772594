<template>
  <main>
    <ValidationObserver
      slot="body"
      tag="form"
      ref="observer"
      @keydown.enter="validateBeforeSubmit"
      vid="form"
      @submit.prevent
      class="form"
    >
      <div
        class="bg-header"
        :class="{ 'short-bg-header': true, 'top-webview' : checkWebview(), 'padding-bg-regis': isHasCaution}"
      ></div>

      <div
        class="container home-content"
        :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
      >
        <h2 class="gift-code-heading">Chibijob ギフト</h2>

        <div class="gift-code">
          <div class="gift-code-wrapper">
            <p class="gift-code-title">
              {{ giftCodeDetail.chibijob_gift_title }}
            </p>

            <img
              :src="giftCodeDetail.chibijob_gift_image"
              alt=""
              :class="{
                'gift-code-image': true,
                'has-text-body': !!giftCodeDetail.chibijob_gift_body
              }"
            >

            <p
              class="gift-code-body"
              v-if="giftCodeDetail.chibijob_gift_body"
              v-html="giftCodeDetail.chibijob_gift_body"
            />

            <InputField
              v-model="form.giftCode"
              class="gift-code-input"
              :vid="giftCodeFieldName"
              field="ギフトコード"
              :max-length="16"
              type="text"
              :rules="{
                required: true
              }"
              @input="handleInputGiftCode($event)"
              v-if="isLoggedIn"
            />
          </div>

          <button
            @click.prevent="validateBeforeSubmit"
            type="submit"
            class="btn gift-code-btn"
            :disabled="isRequesting"
            v-if="isLoggedIn"
          >
            受け取る
            <ChevronRightIcon size="24"/>
          </button>
        </div>

        <button
          class="btn gift-code-btn large"
          @click="onRegister"
          v-if="!isLoggedIn"
        >
          無料会員登録はこちら
        </button>

        <button
          class="btn gift-code-btn no-bg"
          @click="onLogin"
          v-if="!isLoggedIn"
        >
          <ChevronRightIcon size="24"/>
          すでに会員の方はこちらからログイン
        </button>
      </div>
    </ValidationObserver>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import { giftCodeStorageKey } from '@/enum/gift-code'
import store from '@/store'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'
import GiftCode from '@/mixins/giftCode.mixin'
import InputField from '@/components/form/InputField'

export default {
  name: 'Index',

  mixins: [Common, GiftCode],

  components: {
    InputField,
    ChevronRightIcon
  },

  computed: {
    ...mapGetters('giftCode', ['giftCodeDetail']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  data () {
    return {
      form: {
        giftCode: ''
      },
      isRequesting: false,
      giftCodeStorageKey
    }
  },

  async beforeRouteEnter (to, from, next) {
    const code = to.params.code
    const giftCodeResult = await store.dispatch('giftCode/getDetailGiftCodeWithError', [code])

    if (giftCodeResult.status !== 200) {
      next({ name: 'GiftCodeError' })
    } else {
      next()
    }
  },

  async created () {
    if (this.isLoggedIn) {
      await this.userPoint()
    }

    const code = this.$route.params.code

    if (code) {
      this.handleInputGiftCode(code)
    }
  },

  methods: {
    ...mapActions('giftCode', ['getDetailGiftCodeWithError']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.onSubmitGiftCode({
          redirectUrl: 'GiftCodeGet'
        })
      }
    },

    onRegister () {
      this.storeGiftCode(this.form.giftCode)
      this.$router.push({ name: 'Register' })
    },

    onLogin () {
      this.storeGiftCode(this.form.giftCode)
      this.$router.push({ name: 'Login' })
    },

    storeGiftCode (val) {
      Cookie.set(giftCodeStorageKey, val, { expires: 7, secure: true })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_giftcode.scss';

.gift-code {
  &-title {
    margin-bottom: 50px;
  }

  &-image {
    margin-bottom: 40px;
  }

  &-input {
    margin-bottom: 50px;
  }

  &-body {
    margin-bottom: 50px;
  }

  &-btn {
    &.large {
      max-width: 420px;
      font-size: 24px;
    }

    &.no-bg {
      font-size: 14px;
    }
  }
}
</style>
